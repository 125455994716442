import React, { FC } from "react";
import {
  useGroupReservationSession,
  useGroupReservationsInSession,
} from "../../data/queryFunctions";
import { Alert, Paper, Stack, Typography } from "@mui/material";
import { GroupReservation } from "../../data/models";
import GroupReservationDetails from "./GroupReservationDetails";
import { LoadingContextProvider } from "../../contexts/LoadingContext";
import { useTranslation } from "react-i18next";
import LoadingTypography from "../common/LoadingTypography";
import { getEmployeeFullName } from "../../utils/employeeUtils";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";

interface Props {
  groupReservationSessionId: number;
}

const GroupReservationSessionDetails: FC<Props> = ({ groupReservationSessionId }) => {
  const { t } = useTranslation();

  const {
    data: groupReservationSession,
    isLoading: isLoadingGroupReservationSession,
  } = useGroupReservationSession(groupReservationSessionId);
  const {
    data: groupReservations,
    isLoading: isLoadingGroupReservations,
  } = useGroupReservationsInSession(groupReservationSessionId);

  const groupReservationsOrUndefineds: (GroupReservation | undefined)[] = groupReservations ?? [
    undefined,
    undefined,
    undefined,
    undefined,
  ];

  return (
    <Stack direction={"column"} spacing={2}>
      <LoadingContextProvider isLoading={isLoadingGroupReservationSession}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Stack component={Paper} direction={"row"} alignItems={"center"} gap={1} flexGrow={1}>
            <PersonRoundedIcon />
            <LoadingTypography>
              {groupReservationSession
                ? getEmployeeFullName(groupReservationSession.employee)
                : undefined}
            </LoadingTypography>
          </Stack>

          <Stack component={Paper} direction={"row"} alignItems={"center"} gap={1} flexGrow={1}>
            <PeopleRoundedIcon />
            <LoadingTypography>{groupReservationSession?.currentOccupancy}</LoadingTypography>
            <Typography>/</Typography>
            <LoadingTypography>{groupReservationSession?.maxCapacity}</LoadingTypography>
          </Stack>

          <Stack component={Paper} direction={"row"} alignItems={"center"} gap={1} flexGrow={1}>
            <TextSnippetRoundedIcon />
            <LoadingTypography>{groupReservationSession?.notes}</LoadingTypography>
          </Stack>
        </Stack>
      </LoadingContextProvider>

      <LoadingContextProvider isLoading={isLoadingGroupReservations}>
        <Stack direction={"column"} spacing={2}>
          {groupReservationsOrUndefineds.length === 0 && (
            <Alert severity={"info"}>{t("We have no reservations, yet")}</Alert>
          )}

          {groupReservationsOrUndefineds.map((groupReservation, index) => (
            <GroupReservationDetails
              key={groupReservation?.id ?? index.toString()}
              groupReservation={groupReservation}
            />
          ))}
        </Stack>
      </LoadingContextProvider>
    </Stack>
  );
};

export default GroupReservationSessionDetails;
