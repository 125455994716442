import React, { FC } from "react";
import { ReservationType } from "../../models/ReservationType";
import { useTranslation } from "react-i18next";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import { Tooltip } from "@mui/material";

interface Props {
  reservationTypes: ReservationType[];
  onCreate: () => void;
  onEdit: (reservationType: ReservationType) => void;
  onDelete: (reservationType: ReservationType) => void;
}

export const ReservationTypeList: FC<Props> = ({
  onCreate,
  onDelete,
  onEdit,
  reservationTypes,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {(!reservationTypes || reservationTypes.length === 0) && (
        <Card>
          <Card.Body>{t("common.nothingFoundHere")}</Card.Body>
        </Card>
      )}

      <div className="row mb-3">
        {reservationTypes?.map((_) => (
          <div className="col-md-4 mb-4" key={_.id}>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <div className={"d-flex align-items-center"}>
                  <div>{_.name}</div>
                  {_.isForGroups && (
                    <Tooltip title={t<string>("For groups")}>
                      <GroupsRoundedIcon className="ml-2" />
                    </Tooltip>
                  )}
                </div>

                <div>
                  <Button size={"sm"} variant={"dark"} className="mr-2" onClick={() => onEdit(_)}>
                    {t("common.edit")}
                  </Button>
                  <Button size={"sm"} variant={"dark"} onClick={() => onDelete(_)}>
                    {t("common.delete")}
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <b>{t("pages.reservationTypes.duration")}:</b> {_.minutesDuration}{" "}
                {t("pages.reservationTypes.minutes").toLowerCase()}
                <br />
                <b>{t("pages.reservationTypes.price")}:</b> {_.priceEur} €
              </Card.Body>
            </Card>
          </div>
        ))}
        <div className="align-items-center col-md-4 d-flex justify-content-center p-5">
          <Button
            size={"lg"}
            variant={"light"}
            className="px-5 text-uppercase-expanded font-weight-500 shadow"
            onClick={() => onCreate()}
          >
            <FontAwesomeIcon icon={"plus"} className={"mr-3"} />
            {t("common.add")}
          </Button>
        </div>
      </div>
    </>
  );
};
