import { createTheme } from "@mui/material";
import "@mui/material/Button";
import { grey } from "@mui/material/colors";

const textFont = "Poppins";
const headingFont = "Poppins";
export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#1f2d41",
    },
    secondary: {
      main: "#2a3668",
    },
    light: {
      main: grey.A100,
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: `"${textFont}"`,
    fontSize: 14,
    h2: {
      fontFamily: `"${headingFont}"`,
      fontSize: 24,
      fontWeight: 500,
    },
    h4: {
      fontSize: 21,
      fontWeight: 500,
    },
    h5: {
      fontFamily: `"${headingFont}"`,
    },
    h6: {
      fontFamily: `"${headingFont}"`,
      color: "rgba(0,0,0,0.7)",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
        sx: ({ spacing }) => ({
          padding: spacing(3),
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:before": {
            height: 0,
          },
          borderRadius: theme.spacing(1),
          padding: 0,
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              p: 0,
            },
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    light: Palette["primary"];
  }

  interface PaletteOptions {
    light: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
  }
}
