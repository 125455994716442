import { DateTime } from "luxon";

export const queryKeys = {
  groupReservationSessionsBranch: (branchId: number) => [
    "group-reservation-sessions",
    "branch",
    branchId,
  ],
  groupReservationSessionsBranchAndDates: (
    branchId: number,
    dateFrom: DateTime,
    dateTo: DateTime,
  ) => [...queryKeys.groupReservationSessionsBranch(branchId), dateFrom?.toISO(), dateTo?.toISO()],
  groupReservationSessionsBranchDatesAndEmployee: (
    branchId: number,
    dateFrom: DateTime,
    dateTo: DateTime,
    employeeId?: number,
  ) => [
    ...queryKeys.groupReservationSessionsBranchAndDates(branchId, dateFrom, dateTo),
    employeeId,
  ],
  groupReservationSession: (groupReservationSessionId: number) => [
    "group-reservation-session",
    groupReservationSessionId,
  ],

  groupReservationsInSession: (groupReservationSessionId: number) => [
    "group-reservations-in-session",
    groupReservationSessionId,
  ],
  reservationTypesOfBranch: (branchId: number) => ["reservation-types-of-branch", branchId],
  employeesOfBranch: (branchId: number) => ["employees-of-branch", branchId],
};
