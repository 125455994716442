import React, {
  createContext,
  Dispatch,
  FC,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { SET_BRANCH, UserSettingsActionType, UserSettingsReducer } from "./UserSettingsReducer";
import { Branch } from "../models/Branch";
import { Employee } from "../models/Employee";
import useAxios from "axios-hooks";
import { unwrap } from "../helpers/utils";
import { urls } from "../helpers/urls";
import { useAuthContext } from "./AuthContext";

export interface UserSettingsState {
  branch?: Branch;
  employee?: Employee;
  branches: Branch[];
  refreshBranches: () => void;
}

const initialState: UserSettingsState = {
  branches: [],
  refreshBranches: () => {},
};

export const UserSettingsContext = createContext<{
  state: UserSettingsState;
  dispatch: Dispatch<UserSettingsActionType>;
}>({
  state: initialState,
  dispatch: () => {},
});

const UserSettingsContextProvider: FC = ({ children }) => {
  const { isUserLoggedIn, user } = useAuthContext();
  const [{ data: branches }, fetchBranches] = useAxios<Branch[]>({}, { manual: true });
  const [state, dispatch] = useReducer(UserSettingsReducer, initialState);

  const refreshBranches = useCallback(() => {
    if (isUserLoggedIn) {
      const unwrappedUser = unwrap(user);
      fetchBranches({ url: urls.api.branchesOfCompany(unwrappedUser.adminOfCompanyId) });
    }
  }, [isUserLoggedIn, user, fetchBranches]);

  useEffect(() => {
    refreshBranches();
  }, [isUserLoggedIn, refreshBranches]);

  useEffect(() => {
    if (!branches || branches?.length === 0) {
      return;
    }

    dispatch({ type: SET_BRANCH, value: branches[0] });
  }, [branches]);

  useEffect(() => {
    dispatch({ type: SET_BRANCH, value: undefined });
  }, [user]);

  return (
    <UserSettingsContext.Provider
      value={{ state: { ...state, branches: branches ?? [], refreshBranches }, dispatch }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

const useUserSettingsContext = () => useContext(UserSettingsContext);

export { UserSettingsContextProvider, useUserSettingsContext };
