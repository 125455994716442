import React, { createContext, PropsWithChildren, useContext } from "react";

const LoadingContext = createContext<{ isLoading: boolean } | undefined>(undefined);

interface LoadingContextProviderProps {
  isLoading: boolean;
}

export const LoadingContextProvider = ({
  isLoading,
  children,
}: PropsWithChildren<LoadingContextProviderProps>) => {
  return <LoadingContext.Provider value={{ isLoading }}>{children}</LoadingContext.Provider>;
};

export const useLoadingContext = () => {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error("useLoadingContext must be used within LoadingContextProvider");
  }

  return context;
};
