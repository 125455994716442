import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { urls } from "./helpers/urls";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/register/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PrivateRoute from "./common/PrivateRoute";
import CalendarPage from "./pages/CalendarPage";
import { useAuthContext } from "./contexts/AuthContext";
import DashboardPage from "./pages/DashboardPage";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReservationTypesPage from "./pages/ReservationTypesPage";
import BranchesPage from "./pages/BranchesPage";
import EmployeesPage from "./pages/EmployeesPage";
import logo from "./img/svgs/bookinator-logo.min.svg";
import { appName } from "./helpers/utils";
import LoginLayout from "./components/layouts/LoginLayout";
import AccountConfirmationPage from "./pages/register/AccountConfirmationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SettingsPage from "./pages/SettingsPage";
import GroupReservationSessionsPage from "./pages/GroupReservationSessionsPage";
import { useParams } from "react-router-dom";
import GroupReservationSessionPage from "./pages/GroupReservationSessionPage";

const AppRoutes = () => {
  const { t } = useTranslation();
  const { isVerifyingUser } = useAuthContext();

  if (isVerifyingUser) {
    return (
      <LoginLayout withHeader={false} withFooter={false}>
        <div className={"w-100 d-flex flex-column align-items-center"}>
          <img src={logo} alt={appName} className={"w-25"} style={{ maxWidth: "12em" }} />
          <Spinner animation="border" variant={"dark"} role="status" className="mt-2">
            <span className="sr-only">{t("common.loading")}...</span>
          </Spinner>
        </div>
      </LoginLayout>
    );
  }

  return (
    <Switch>
      <Redirect exact from={urls.home} to={urls.calendar} />

      <Route path={urls.login}>
        <LoginPage />
      </Route>
      <Route path={urls.registerForm}>
        <RegisterPage />
      </Route>
      <Route path={urls.confirmAccount}>
        <AccountConfirmationPage />
      </Route>
      <Route path={urls.forgotPassword}>
        <ForgotPasswordPage />
      </Route>
      <Route path={urls.resetPassword}>
        <ResetPasswordPage />
      </Route>

      <PrivateRoute path={urls.dashboard}>
        <DashboardPage />
      </PrivateRoute>
      <PrivateRoute path={urls.calendar}>
        <CalendarPage />
      </PrivateRoute>
      <PrivateRoute path={urls.companyBranches}>
        <BranchesPage />
      </PrivateRoute>
      <PrivateRoute path={urls.companyEmployees}>
        <EmployeesPage />
      </PrivateRoute>
      <PrivateRoute path={urls.companyReservationTypes}>
        <ReservationTypesPage />
      </PrivateRoute>
      <PrivateRoute path={urls.groupReservationSessions.single.pattern()}>
        <GroupReservationSessionPage />
      </PrivateRoute>
      <PrivateRoute path={urls.groupReservationSessions.list}>
        <GroupReservationSessionsPage />
      </PrivateRoute>
      <PrivateRoute path={urls.settings}>
        <SettingsPage />
      </PrivateRoute>
    </Switch>
  );
};

export default AppRoutes;
