import React, { FC, PropsWithChildren } from "react";
import { Paper, PaperProps, useTheme } from "@mui/material";
import { useIsTouchDevice } from "../../hooks/useIsTouchDevice";

export type Props = PaperProps;

const HoverablePaper: FC<PropsWithChildren<Props>> = (props) => {
  const { palette, ...theme } = useTheme();
  const isTouchDevice = useIsTouchDevice();

  // TODO refactor this somewhere to palette
  const whiteRgba = "255, 255, 255";
  const blackRgba = "0, 0, 0";
  const nonHover = (color: string) => `rgba(${color}, 0.02)`;
  const hover = (color: string) => `rgba(${color}, 0.08)`;
  const background = palette.mode === "light" ? nonHover(blackRgba) : nonHover(whiteRgba);
  const hoverBackground = palette.mode === "light" ? hover(blackRgba) : hover(whiteRgba);

  const newSx = {
    ...props.sx,
    transition: theme.transitions.create("background"),
    background,
    "&:hover": { background: isTouchDevice ? background : hoverBackground },
    "& .reactToHover": {
      transition: theme.transitions.create("opacity"),
    },
    "&:hover .reactToHover": {
      opacity: 1,
    },
    cursor: props.onClick ? "pointer" : "inherit",
  };

  return (
    <Paper {...props} sx={newSx}>
      {props.children}
    </Paper>
  );
};

export default HoverablePaper;
