import React, { FC, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import { LoadingButton } from "@mui/lab";

type Props = Omit<ButtonProps, "onClick"> & {
  confirm: {
    title?: string;
    description: string;
    confirmButtonText: string;
    confirmButtonProps?: ButtonProps;
    declineButtonText: string;
    declineButtonProps?: ButtonProps;
    onClick: () => void;
  };
  onClick?: () => void;
  isLoading?: boolean;
};

const ButtonWithConfirmAction: FC<Props> = ({ confirm, isLoading, children, ...nativeProps }) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const onClick = () => {
    setIsClicked(true);

    if (nativeProps.onClick) {
      nativeProps.onClick();
    }
  };

  const decline = () => setIsClicked(false);

  return (
    <>
      <LoadingButton loading={isLoading} variant={"contained"} onClick={onClick} {...nativeProps}>
        {children}
      </LoadingButton>

      {isClicked && (
        <Dialog
          open={isClicked}
          onClose={decline}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {confirm.title && <DialogTitle id="alert-dialog-title">{confirm.title}</DialogTitle>}

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {confirm.description}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={decline} {...confirm.declineButtonProps}>
              {confirm.declineButtonText}
            </Button>
            <Button onClick={confirm.onClick} {...confirm.confirmButtonProps}>
              {confirm.confirmButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default ButtonWithConfirmAction;
