import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import ButtonWithConfirmAction from "../common/ButtonWithConfirmAction";
import { useTranslation } from "react-i18next";

interface Props {
  deleteGroupReservationSession: () => void;
  isDeleting: boolean;
}

const GroupReservationSessionOtherSettings: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ px: 1, py: 2 }}
      gap={2}
    >
      <Stack direction={"column"}>
        <Typography variant={"body1"}>{t("Delete group reservation")}</Typography>
        <Typography variant={"body2"}>
          {t("All details, customers and their reservations will be deleted")}
        </Typography>
      </Stack>

      <Stack justifyContent={"flex-end"}>
        <ButtonWithConfirmAction
          isLoading={props.isDeleting}
          color={"error"}
          confirm={{
            confirmButtonText: t("common.delete"),
            confirmButtonProps: { color: "error" },
            declineButtonText: t("common.cancel"),
            description: t("All details, customers and their reservations will be deleted"),
            onClick: props.deleteGroupReservationSession,
          }}
        >
          {t("common.delete")}
        </ButtonWithConfirmAction>
      </Stack>
    </Stack>
  );
};

export default GroupReservationSessionOtherSettings;
