import React, { FC, useState } from "react";
import { useGroupReservationSessions } from "../../data/queryFunctions";
import { DateTime } from "luxon";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import GroupReservationSessionsListItem from "./GroupReservationSessionsListItem";
import OverlayContainer from "../../common/OverlayContainer/OverlayContainer";
import GroupReservationSessionForm from "./GroupReservationSessionForm";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { groupGroupReservationSessionsByDate } from "../../utils/groupReservationSessionsUtils";

interface Props {
  branchId: number;
  dateFrom: DateTime;
  dateTo: DateTime;
  employeeId?: number;
}

const GroupReservationSessionsList: FC<Props> = ({ branchId, dateFrom, dateTo, employeeId }) => {
  const { t } = useTranslation();

  const [dateTimeForNewSession, setDateTimeForNewSession] = useState<DateTime>();

  const { data: groupReservationSessions, isLoading } = useGroupReservationSessions(
    branchId,
    dateFrom,
    dateTo,
    employeeId,
  );
  const groupReservationSessionsByDay = groupReservationSessions
    ? groupGroupReservationSessionsByDate(groupReservationSessions, dateFrom, dateTo)
    : [];

  const onFormClose = () => {
    setDateTimeForNewSession(undefined);
  };

  return (
    <>
      {groupReservationSessionsByDay.map((_) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography variant={"h6"}>{_.date.toLocaleString(DateTime.DATE_SHORT)}</Typography>
              <Button
                variant={"outlined"}
                onClick={() => {
                  setDateTimeForNewSession(_.date);
                }}
                startIcon={<AddIcon />}
                size={"small"}
              >
                {t("Add")}
              </Button>
            </Stack>
          </Grid>

          {_.groupReservationSessions.map((__) => (
            <Grid key={__.id} item xs={12} md={6} lg={4}>
              <GroupReservationSessionsListItem groupReservationSession={__} />
            </Grid>
          ))}

          <Grid item xs={12} sx={{ mb: 2 }}>
            <Divider />
          </Grid>
        </Grid>
      ))}

      {dateTimeForNewSession && (
        <OverlayContainer title={t("Create new group reservation session")} onClose={onFormClose}>
          <GroupReservationSessionForm
            defaultDate={dateTimeForNewSession.set({ hour: 10, minute: 0 })}
            onSave={() => setDateTimeForNewSession(undefined)}
            onCancel={onFormClose}
          />
        </OverlayContainer>
      )}
    </>
  );
};

export default GroupReservationSessionsList;
