import React, { FC, ReactElement } from "react";
import cx from "classnames";

import "./Header.scss";

interface Props {
  title: string;
  subtitle?: string;
  toolbar?: ReactElement;
}

const Header: FC<Props> = (props) => {
  return (
    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10 shadow">
      <div className="container">
        <div className="page-header-content pt-4">
          <div className="row align-items-center justify-content-between mt-4">
            <div
              className={cx(
                "d-flex justify-content-md-start justify-content-center mb-2 flex-column",
                props.toolbar ? "col-md-6 col-lg-4" : "col",
              )}
            >
              <h1 className="page-header-title">{props.title}</h1>
              {props.subtitle && <div className="page-header-subtitle">{props.subtitle}</div>}
            </div>

            {props.toolbar && (
              <div
                className={
                  "col-md-6 col-lg-8 d-flex justify-content-md-end justify-content-center mb-2"
                }
              >
                {props.toolbar}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
