import React, { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useUserSettingsContext } from "../../contexts/UserSettingsContext";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { GroupReservationSession } from "../../data/models";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  useCreateReservationGroupSession,
  useDeleteReservationGroupSession,
  useUpdateReservationGroupSession,
} from "../../data/mutationFunctions";
import { CreateGroupReservationSessionRequestDto } from "../../data/dtos";
import { useEmployeesOfBranch, useReservationTypesOfBranch } from "../../data/queryFunctions";
import { unwrap } from "../../helpers/utils";
import { getReservationTypeByIdOrThrow } from "../../utils/reservationTypesUtils";
import { ReservationType } from "../../models/ReservationType";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupReservationSessionOtherSettings from "./GroupReservationSessionOtherSettings";
import { useHistory } from "react-router-dom";
import { urls } from "../../helpers/urls";
import { toApiDateTimeString } from "../../helpers/DateTimeService";

const MIN_ALLOWED_CAPACITY = 1;

interface IGroupReservationSessionForm {
  startingAt: DateTime;
  reservationTypeId?: number;
  employeeId?: number;
  maxCapacity: number;
  notes: string;
}

const getRequestDto = (
  formData: IGroupReservationSessionForm,
  reservationTypes: ReservationType[],
  branchId: number,
) => {
  if (!formData.reservationTypeId || !formData.employeeId) {
    throw new Error("Reservation type or employee is not defined");
  }

  const reservationType = getReservationTypeByIdOrThrow(
    reservationTypes ?? [],
    formData.reservationTypeId,
  );

  return {
    startingAt: toApiDateTimeString(formData.startingAt),
    endingAt: toApiDateTimeString(
      formData.startingAt.plus({ minutes: reservationType.minutesDuration }),
    ),
    maxCapacity: formData.maxCapacity,
    notes: formData.notes,
    reservationTypeId: formData.reservationTypeId,
    employeeId: formData.employeeId,
    currentOccupancy: 0,
    branchId: branchId,
  };
};

interface Props {
  groupReservationSession?: GroupReservationSession;
  defaultDate?: DateTime;
  onSave: () => void;
  onCancel: () => void;
}

const GroupReservationSessionForm: FC<Props> = ({
  groupReservationSession,
  defaultDate,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { state: userSettings } = useUserSettingsContext();

  const branchId = unwrap(userSettings.branch?.id);

  const {
    data: reservationTypes,
    isLoading: isLoadingReservationTypes,
  } = useReservationTypesOfBranch(branchId);
  const groupReservationTypes = reservationTypes?.filter((_) => _.isForGroups);

  const { data: employees, isLoading: isLoadingEmployees } = useEmployeesOfBranch(branchId);

  const { mutate: create, isLoading: isCreating } = useCreateReservationGroupSession(branchId);
  const { mutate: update, isLoading: isUpdating } = useUpdateReservationGroupSession(branchId);
  const { mutate: remove, isLoading: isRemoving } = useDeleteReservationGroupSession(branchId);

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isValid },
  } = useForm<IGroupReservationSessionForm>({
    defaultValues: groupReservationSession
      ? {
          startingAt: groupReservationSession.startingAt,
          reservationTypeId: groupReservationSession.reservationTypeId,
          employeeId: groupReservationSession.employeeId,
          maxCapacity: groupReservationSession.maxCapacity,
          notes: groupReservationSession.notes,
        }
      : {
          startingAt: defaultDate ?? DateTime.now().set({ hour: 8, minute: 0 }),
          maxCapacity: 5,
          notes: "",
        },
    mode: "onChange",
  });

  const createGroupReservationSession = (
    formData: IGroupReservationSessionForm,
    onSaveFn: () => void,
    onErrorFn: () => void,
  ) => {
    const requestBody = getRequestDto(formData, groupReservationTypes ?? [], branchId);

    create(requestBody, {
      onSuccess: onSaveFn,
      onError: onErrorFn,
    });
  };

  const updateGroupReservationSession = (
    formData: IGroupReservationSessionForm,
    id: number,
    onSaveFn: () => void,
    onErrorFn: () => void,
  ) => {
    const requestBody: CreateGroupReservationSessionRequestDto & { id: number } = {
      ...getRequestDto(formData, groupReservationTypes ?? [], branchId),
      id: id,
    };

    update(requestBody, { onSuccess: onSaveFn, onError: onErrorFn });
  };

  const onSubmit = (data: IGroupReservationSessionForm) => {
    const onSaveFn = () => {
      enqueueSnackbar(t("common.savedSuccessfully"), { variant: "success" });
      onSave();
    };

    const onErrorFn = () => {
      enqueueSnackbar(t("common.savingFailedTryAgain"), { variant: "error" });
    };

    if (groupReservationSession?.id) {
      updateGroupReservationSession(data, groupReservationSession.id, onSaveFn, onErrorFn);
    } else {
      createGroupReservationSession(data, onSaveFn, onErrorFn);
    }
  };

  const deleteGroupReservationSession = () => {
    if (groupReservationSession?.id) {
      remove(groupReservationSession.id, {
        onSuccess: () => {
          enqueueSnackbar(t("common.deletedSuccessfully"), { variant: "success" });
          history.push(urls.groupReservationSessions.list);
        },
        onError: () => {
          enqueueSnackbar(t("common.deletingFailedTryAgain"), { variant: "error" });
        },
      });
    }
  };

  const isEditingGroupReservationSession = !!groupReservationSession;
  const isSavingGroupReservationSession = isCreating || isUpdating;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={"column"} spacing={2}>
        <FormControl fullWidth error={!!errors.reservationTypeId?.message}>
          <InputLabel id="reservation-type-label">{t("Service")}</InputLabel>
          <Controller
            name={"reservationTypeId"}
            control={control}
            rules={{ required: t<string>("errors.fieldCannotBeEmpty") }}
            render={(field) => (
              <Select labelId="reservation-type-label" label={t("Service")} {...field}>
                {groupReservationTypes?.map((_) => (
                  <MenuItem key={_.id} value={_.id}>
                    {_.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>{errors.reservationTypeId?.message}</FormHelperText>
        </FormControl>

        <FormControl fullWidth error={!!errors.employeeId?.message}>
          <InputLabel id="employee-label">{t("Employee")}</InputLabel>
          <Controller
            name={"employeeId"}
            control={control}
            rules={{ required: t<string>("errors.fieldCannotBeEmpty") }}
            render={(field) => (
              <Select labelId="employee-label" label={t("Employee")} {...field}>
                {employees?.map((_) => (
                  <MenuItem key={_.id} value={_.id}>
                    {_.firstName} {_.lastName}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>{errors.employeeId?.message}</FormHelperText>
        </FormControl>

        <Controller
          name={"startingAt"}
          control={control}
          rules={{ required: t<string>("errors.fieldCannotBeEmpty") }}
          render={(field) => <DateTimePicker<DateTime> {...field} label={t("Starting at")} />}
        />

        <TextField
          inputRef={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
            min: {
              value: MIN_ALLOWED_CAPACITY,
              message: t("errors.minimalValueIsX", { minimalValue: MIN_ALLOWED_CAPACITY }),
            },
          })}
          name={"maxCapacity"}
          id={"maxCapacity"}
          label={t("Maximum capacity")}
          type={"number"}
          error={!!errors.maxCapacity?.message}
          helperText={errors.maxCapacity?.message}
        />

        <TextField
          inputRef={register()}
          name={"notes"}
          id={"notes"}
          label={t("Notes")}
          error={!!errors.notes?.message}
          helperText={errors.notes?.message}
        />

        {isEditingGroupReservationSession && (
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("pages.branches.showOtherSettings")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GroupReservationSessionOtherSettings
                deleteGroupReservationSession={deleteGroupReservationSession}
                isDeleting={isRemoving}
              />
            </AccordionDetails>
          </Accordion>
        )}

        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
          <LoadingButton
            color={"primary"}
            variant={"contained"}
            type={"submit"}
            className={"mr-2"}
            loading={isSavingGroupReservationSession}
            disabled={isLoadingReservationTypes || isLoadingEmployees || !isValid}
          >
            {t("common.save")}
          </LoadingButton>

          <Button variant={"outlined"} onClick={onCancel}>
            {t("common.cancel")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default GroupReservationSessionForm;
