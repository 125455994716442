import { ReservationType } from "../models/ReservationType";

export const getReservationTypeByIdOrThrow = (
  reservationTypes: ReservationType[],
  id: number,
): ReservationType => {
  const reservationType = reservationTypes.find((type) => type.id === id);
  if (!reservationType) {
    throw new Error(`Reservation type with id ${id} not found`);
  }

  return reservationType;
};
