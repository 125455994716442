import React, { FC } from "react";
import { GroupReservationSession } from "../../data/models";
import { Stack, Typography } from "@mui/material";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import HoverablePaper from "../common/HoverablePaper";
import { useHistory } from "react-router-dom";
import { urls } from "../../helpers/urls";
import { getGroupReservationSessionDateRangeReadable } from "../../utils/groupReservationSessionsUtils";
import { getEmployeeFullName } from "../../utils/employeeUtils";

interface Props {
  groupReservationSession: GroupReservationSession | undefined;
}

const GroupReservationSessionsListItem: FC<Props> = ({ groupReservationSession }) => {
  const history = useHistory();

  const dateRange = groupReservationSession
    ? getGroupReservationSessionDateRangeReadable(groupReservationSession)
    : "";

  const goToDetails = () => {
    if (!groupReservationSession) {
      return;
    }

    history.push(urls.groupReservationSessions.single.actual(groupReservationSession.id));
  };

  return (
    <HoverablePaper onClick={goToDetails} sx={{ p: 2 }}>
      <Stack direction={"column"} spacing={1}>
        <Typography variant={"h6"} component={"h2"}>
          {groupReservationSession?.reservationType.name}
        </Typography>

        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <AccessTimeRoundedIcon />
          <Typography variant={"body2"}>{dateRange}</Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <PersonRoundedIcon />
          <Typography variant={"body2"}>
            {groupReservationSession
              ? getEmployeeFullName(groupReservationSession.employee)
              : undefined}
          </Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <PeopleRoundedIcon />
          <Typography variant={"body2"}>
            {groupReservationSession?.currentOccupancy}/{groupReservationSession?.maxCapacity}
          </Typography>
        </Stack>
      </Stack>
    </HoverablePaper>
  );
};

export default GroupReservationSessionsListItem;
