import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import {
  createGroupReservationSession,
  deleteGroupReservationSession,
  updateGroupReservationSession,
} from "./apiFunctions";
import { CreateGroupReservationSessionRequestDto } from "./dtos";

export const useCreateReservationGroupSession = (branchId: number) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, void, CreateGroupReservationSessionRequestDto, unknown>({
    mutationFn: (request) => createGroupReservationSession(request),
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.groupReservationSessionsBranch(branchId));
    },
  });
};

export const useUpdateReservationGroupSession = (branchId: number) => {
  const queryClient = useQueryClient();

  return useMutation<
    { id: number },
    void,
    CreateGroupReservationSessionRequestDto & { id: number },
    unknown
  >({
    mutationFn: async (request) => {
      const { id, ...rest } = request;
      await updateGroupReservationSession(id, rest);
      return { id };
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(queryKeys.groupReservationSessionsBranch(branchId));
      await queryClient.invalidateQueries(queryKeys.groupReservationSession(data.id));
      await queryClient.invalidateQueries(queryKeys.groupReservationsInSession(data.id));
    },
  });
};

export const useDeleteReservationGroupSession = (branchId: number) => {
  const queryClient = useQueryClient();

  return useMutation<number, void, number, unknown>({
    mutationFn: async (id) => {
      await deleteGroupReservationSession(id);
      return id;
    },
    onSuccess: async (id) => {
      await queryClient.invalidateQueries(queryKeys.groupReservationSessionsBranch(branchId));
      queryClient.removeQueries(queryKeys.groupReservationSession(id));
      queryClient.removeQueries(queryKeys.groupReservationsInSession(id));
    },
  });
};
