import React, { FC, PropsWithChildren, useState } from "react";
import { QueryCache, QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const QueryClientProviderWithDefaults: FC<PropsWithChildren<{}>> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => {
            // 🎉 only show error toasts if we already have data in the cache which indicates a failed background update
            if (query.state.data !== undefined) {
              enqueueSnackbar(t("Failed to load data"), {
                variant: "error",
              });
            }
          },
        }),
      }),
  );

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};
