import React, { FC, ReactElement } from "react";
import Footer from "../common/Footer";
import Header from "../common/header/Header";
import AppBar from "../common/header/AppBar";
import cx from "classnames";

interface Props {
  title: string;
  subtitle?: string;
  toolbar?: ReactElement;
  isSolidBackground?: boolean;
}

const AdminLayout: FC<Props> = (props) => {
  return (
    <>
      <AppBar />
      <Header title={props.title} subtitle={props.subtitle} toolbar={props.toolbar} />
      <div
        className={cx("container mt-n10 py-3", { "bg-gray-100 rounded": props.isSolidBackground })}
      >
        {props.children}
      </div>
      <Footer />
    </>
  );
};

export default AdminLayout;
