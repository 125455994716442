import {
  BranchDto,
  CompanyDto,
  CustomerDto,
  EmployeeDto,
  GroupReservationDto,
  GroupReservationSessionDto,
  ReservationTypeDto,
  UserDto,
} from "./dtos";
import { GroupReservation, GroupReservationSession } from "./models";
import { Branch } from "../models/Branch";
import { Employee } from "../models/Employee";
import { ReservationType } from "../models/ReservationType";
import { Company } from "../models/Company";
import { User } from "../models/User";
import { parseDateTimeFromApi } from "../helpers/DateTimeService";
import { Customer } from "../models/Customer";

export const dtoToModel = {
  groupReservation: (dto: GroupReservationDto): GroupReservation => ({
    ...dto,
    customer: dtoToModel.customer(dto.customer),
    groupReservationSession: dto.groupReservationSession
      ? dtoToModel.groupReservationSession(dto.groupReservationSession)
      : undefined,
  }),
  groupReservationSession: (dto: GroupReservationSessionDto): GroupReservationSession => ({
    ...dto,
    startingAt: parseDateTimeFromApi(dto.startingAt),
    endingAt: parseDateTimeFromApi(dto.endingAt),
    branch: dto.branch ? dtoToModel.branch(dto.branch) : undefined,
    employee: dtoToModel.employee(dto.employee),
    reservationType: dtoToModel.reservationType(dto.reservationType),
  }),
  branch: (dto: BranchDto): Branch => ({
    ...dto,
    company: dto.company ? dtoToModel.company(dto.company) : undefined,
  }),
  employee: (dto: EmployeeDto): Employee => ({
    ...dto,
    branch: dto.branch ? dtoToModel.branch(dto.branch) : undefined,
  }),
  reservationType: (dto: ReservationTypeDto): ReservationType => dto,
  company: (dto: CompanyDto): Company => ({
    ...dto,
    owner: dtoToModel.user(dto.owner),
  }),
  customer: (dto: CustomerDto): Customer => ({ ...dto }),
  user: (dto: UserDto): User => dto,
};
