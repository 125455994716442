import { DateTime } from "luxon";
import axiosInstance from "../axios";
import { apiUrls } from "../helpers/urls";
import {
  CreateGroupReservationSessionRequestDto,
  EmployeeDto,
  GroupReservationDto,
  GroupReservationSessionDto,
  ReservationTypeDto,
} from "./dtos";
import { dtoToModel } from "./dtoToModel";
import { toApiDateTimeString } from "../helpers/DateTimeService";

export const getGroupReservationSessions = (
  branchId: number,
  dateFrom: DateTime,
  dateTo: DateTime,
  employeeId?: number,
) =>
  axiosInstance
    .get<GroupReservationSessionDto[]>(apiUrls.groupReservationSessions, {
      params: {
        branchId,
        from: toApiDateTimeString(dateFrom),
        until: toApiDateTimeString(dateTo),
        employeeId,
      },
    })
    .then((_) => _.data.map(dtoToModel.groupReservationSession));

export const getGroupReservationSession = (groupReservationSessionId: number) =>
  axiosInstance
    .get<GroupReservationSessionDto>(apiUrls.groupReservationSession(groupReservationSessionId))
    .then((_) => dtoToModel.groupReservationSession(_.data));

export const getGroupReservationsInSession = (groupReservationSessionId: number) =>
  axiosInstance
    .get<GroupReservationDto[]>(apiUrls.groupReservationsInSession, {
      params: { groupReservationSessionId },
    })
    .then((_) => _.data.map(dtoToModel.groupReservation));

export const createGroupReservationSession = (
  groupReservationSession: CreateGroupReservationSessionRequestDto,
) => axiosInstance.post<number>(apiUrls.groupReservationSessions, groupReservationSession);

export const updateGroupReservationSession = (
  id: number,
  groupReservationSession: CreateGroupReservationSessionRequestDto,
) =>
  axiosInstance.put<GroupReservationSessionDto>(
    apiUrls.groupReservationSession(id),
    groupReservationSession,
  );

export const deleteGroupReservationSession = (groupReservationSessionId: number) =>
  axiosInstance.delete(apiUrls.groupReservationSession(groupReservationSessionId));

export const getReservationTypesOfBranch = (branchId: number) =>
  axiosInstance
    .get<ReservationTypeDto[]>(apiUrls.reservationTypesOfBranch(branchId))
    .then((_) => _.data.map(dtoToModel.reservationType));

export const getEmployeesOfBranch = (branchId: number) =>
  axiosInstance
    .get<EmployeeDto[]>(apiUrls.employeesOfBranch(branchId))
    .then((_) => _.data.map(dtoToModel.employee));
