import React from "react";

import "./OverlayContainer.scss";
import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";

interface Props {
  title: string;
  onClose?: () => void;
  variant?: "standard" | "wide";
}

const OverlayContainer: React.FC<Props> = ({ children, title, onClose, variant = "standard" }) => {
  return (
    <Dialog onClose={onClose} open={true} fullWidth maxWidth={"md"}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default OverlayContainer;
