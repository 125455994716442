import React, { FC } from "react";
import { GroupReservation } from "../../data/models";
import { Paper, Stack } from "@mui/material";
import LoadingTypography from "../common/LoadingTypography";

interface Props {
  groupReservation: GroupReservation | undefined;
}

const GroupReservationDetails: FC<Props> = ({ groupReservation }) => {
  return (
    <Stack component={Paper} sx={{ p: 2 }}>
      <LoadingTypography variant={"h6"}>
        {groupReservation?.customer.firstName} {groupReservation?.customer.lastName}{" "}
      </LoadingTypography>
      <LoadingTypography variant={"body1"}>{groupReservation?.customer.email}</LoadingTypography>
      <LoadingTypography variant={"body1"}>
        {groupReservation?.customer.phoneNumber}
      </LoadingTypography>
      <LoadingTypography variant={"body1"}>{groupReservation?.notes}</LoadingTypography>
    </Stack>
  );
};

export default GroupReservationDetails;
