import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import { DateTime } from "luxon";
import {
  getEmployeesOfBranch,
  getGroupReservationSession,
  getGroupReservationSessions,
  getGroupReservationsInSession,
  getReservationTypesOfBranch,
} from "./apiFunctions";

export const useGroupReservationSessions = (
  branchId: number,
  dateFrom: DateTime,
  dateTo: DateTime,
  employeeId?: number,
) =>
  useQuery({
    queryKey: queryKeys.groupReservationSessionsBranchDatesAndEmployee(
      branchId,
      dateFrom,
      dateTo,
      employeeId,
    ),
    queryFn: () => getGroupReservationSessions(branchId, dateFrom, dateTo),
  });

export const useGroupReservationSession = (groupReservationSessionId: number) =>
  useQuery({
    queryKey: queryKeys.groupReservationSession(groupReservationSessionId),
    queryFn: () => getGroupReservationSession(groupReservationSessionId),
  });

export const useGroupReservationsInSession = (groupReservationSessionId: number) =>
  useQuery({
    queryKey: queryKeys.groupReservationsInSession(groupReservationSessionId),
    queryFn: () => getGroupReservationsInSession(groupReservationSessionId),
  });

export const useReservationTypesOfBranch = (branchId: number) =>
  useQuery({
    queryKey: queryKeys.reservationTypesOfBranch(branchId),
    queryFn: () => getReservationTypesOfBranch(branchId),
  });

export const useEmployeesOfBranch = (branchId: number) =>
  useQuery({
    queryKey: queryKeys.employeesOfBranch(branchId),
    queryFn: () => getEmployeesOfBranch(branchId),
  });
