import {
  Skeleton,
  SkeletonProps,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { FC } from "react";
import { useLoadingContext } from "../../contexts/LoadingContext";

interface LoadingTypographyProps extends TypographyProps {
  sx?: SxProps<Theme>;
  skeletonProps?: SkeletonProps;
}

const LoadingTypography: FC<LoadingTypographyProps> = ({
  sx,
  skeletonProps: originalSkeletonProps,
  ...props
}) => {
  const { isLoading } = useLoadingContext();

  const skeletonProps: SkeletonProps = {
    width: 50,
    ...originalSkeletonProps,
  };

  return (
    <Typography
      sx={{
        ...sx,
      }}
      {...props}
    >
      {isLoading && <Skeleton {...skeletonProps} />}
      {!isLoading && props.children}
    </Typography>
  );
};

export default LoadingTypography;
