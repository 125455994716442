import React, { FC, useState } from "react";
import AdminLayout from "../components/layouts/AdminLayout";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import GroupReservationSessionDetails from "../components/group-reservations/GroupReservationSessionDetails";
import { useGroupReservationSession } from "../data/queryFunctions";
import { LoadingContextProvider } from "../contexts/LoadingContext";
import { getGroupReservationSessionDateRangeReadable } from "../utils/groupReservationSessionsUtils";
import { Button } from "@mui/material";
import GroupReservationSessionForm from "../components/group-reservations/GroupReservationSessionForm";
import OverlayContainer from "../common/OverlayContainer/OverlayContainer";

const GroupReservationSessionPage: FC = () => {
  const { groupReservationSessionId: groupReservationSessionIdRaw } = useParams<{
    groupReservationSessionId: string;
  }>();
  const groupReservationSessionId = parseInt(groupReservationSessionIdRaw);

  const { t } = useTranslation();
  const { data: groupReservationSession, isLoading } = useGroupReservationSession(
    groupReservationSessionId,
  );

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const subtitle = groupReservationSession
    ? getGroupReservationSessionDateRangeReadable(groupReservationSession, true)
    : "";

  return (
    <LoadingContextProvider isLoading={isLoading}>
      <AdminLayout
        title={groupReservationSession?.reservationType.name ?? "..."}
        subtitle={subtitle}
        toolbar={
          <Button onClick={() => setIsEditOpen(true)} color={"light"}>
            {t("Edit")}
          </Button>
        }
        isSolidBackground
      >
        <GroupReservationSessionDetails groupReservationSessionId={groupReservationSessionId} />
      </AdminLayout>

      {isEditOpen && groupReservationSession && (
        <OverlayContainer
          title={t("Edit group reservation session")}
          onClose={() => setIsEditOpen(false)}
        >
          <GroupReservationSessionForm
            groupReservationSession={groupReservationSession}
            onSave={() => {
              setIsEditOpen(false);
            }}
            onCancel={() => setIsEditOpen(false)}
          />
        </OverlayContainer>
      )}
    </LoadingContextProvider>
  );
};

export default GroupReservationSessionPage;
