import React, { FC, useState } from "react";
import AdminLayout from "../components/layouts/AdminLayout";
import { useTranslation } from "react-i18next";
import GroupReservationSessionsList from "../components/group-reservations/GroupReservationSessionsList";
import { useUserSettingsContext } from "../contexts/UserSettingsContext";
import CalendarNavigation from "../components/calendar/CalendarNavigation";
import { DateTime } from "luxon";
import { Employee } from "../models/Employee";

const GroupReservationSessionsPage: FC = () => {
  const { t } = useTranslation();

  const {
    state: { branch },
  } = useUserSettingsContext();

  const [dateFrom, setDateFrom] = useState<DateTime>(DateTime.now().startOf("week").startOf("day"));
  const [dateTo, setDateTo] = useState<DateTime>(dateFrom.plus({ days: 7 }));
  const [filteredEmployee, setFilteredEmployee] = useState<Employee>();

  const changeWeek = (to: "previous" | "next") => {
    const addDaysCount = to === "previous" ? -7 : 7;

    setDateFrom(dateFrom.plus({ days: addDaysCount }));
    setDateTo(dateTo.plus({ days: addDaysCount }));
  };

  return (
    <AdminLayout
      title={t("Group reservations")}
      toolbar={
        <CalendarNavigation
          showPreviousWeek={() => changeWeek("previous")}
          showNextWeek={() => changeWeek("next")}
          filterEmployee={(_) => setFilteredEmployee(_)}
        />
      }
      isSolidBackground
    >
      {branch && (
        <GroupReservationSessionsList
          branchId={branch.id}
          dateFrom={dateFrom}
          dateTo={dateTo}
          employeeId={filteredEmployee?.id}
        />
      )}
    </AdminLayout>
  );
};

export default GroupReservationSessionsPage;
