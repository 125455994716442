import { GroupReservationSession } from "../data/models";
import { DateTime } from "luxon";

export const groupGroupReservationSessionsByDate = (
  groupReservationSessions: GroupReservationSession[],
  dateFrom: DateTime,
  dateTo: DateTime,
) => {
  const grouped = groupReservationSessions.reduce((acc, current) => {
    const date = current.startingAt.toISODate();
    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(current);

    return acc;
  }, {} as Record<string, GroupReservationSession[]>);

  const dates = dateFrom
    .until(dateTo)
    .splitBy({ days: 1 })
    .map((_) => _.start);

  return dates.map((date) => ({
    date,
    groupReservationSessions: grouped[date.toISODate()] || [],
  }));
};

export const getGroupReservationSessionDateRangeReadable = (
  groupReservationSession: GroupReservationSession,
  includeDate = false,
) => {
  const isReservationWithinOneDay = groupReservationSession.startingAt.hasSame(
    groupReservationSession.endingAt,
    "day",
  );

  let datePrefix = "";
  if (includeDate) {
    datePrefix = `${groupReservationSession.startingAt.toFormat("dd.MM. (ccc)")}, `;
  }

  return isReservationWithinOneDay
    ? `${datePrefix}${groupReservationSession.startingAt.toLocaleString(
        DateTime.TIME_24_SIMPLE,
      )} - ${groupReservationSession.endingAt.toLocaleString(DateTime.TIME_24_SIMPLE)}`
    : `${groupReservationSession.startingAt.toLocaleString(
        DateTime.TIME_24_SIMPLE,
      )} - ${groupReservationSession.endingAt.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
};
